<template>
  <div class="faq" :class="{ '': !expanded, active: expanded }">
    <p class="faq__title p-3 bold" @click="expanded = !expanded">
      {{ title }}
    </p>
    <v-transition-expand>
      <p v-if="expanded" class="faq__text p-4">{{ text }}</p>
    </v-transition-expand>
  </div>
</template>

<script>
export default {
  name: "v-faq",
  components: {
    "v-transition-expand": () => import("@/components/TransitionExpand.vue"),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
}
.expand-enter,
.expand-leave-to {
  opacity: 0;
}

.faq {
  $self: &;
  padding-top: $space-24;
  margin-top: -1px;
  border-top: 1px solid $gray-100;
  border-bottom: 1px solid $gray-100;

  &__title {
    position: relative;
    margin-bottom: $space-24 !important;
    padding-right: $space-56;
    cursor: pointer;
    transition: color $trans-anim;

    &::after {
      content: "";
      position: absolute;
      top: 9px;
      right: 4px;
      width: 12px;
      height: 12px;
      border-top: 2px solid $gray-500;
      border-left: 2px solid $gray-500;
      transform: rotate(135deg);
      transition: transform $trans-anim, border-color $trans-anim;

      @include mobile {
        top: 7px;
        width: 10px;
        height: 10px;
      }
    }

    &:hover {
      color: $green-700;

      &::after {
        border-color: $gray-900;
      }
    }
  }

  &__text {
    margin: 0;

    @include mobile {
      font-size: 16px;
    }
  }

  &.active {
    padding-bottom: $space-24;
    #{ $self } {
      &__title {
        &::after {
          right: 0;
          transform: rotate(225deg);
        }
      }
    }
  }
}
</style>
